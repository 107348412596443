import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createOrder } from "../Redux/Actions/OrderActions";
import { ORDER_CREATE_RESET } from "../Redux/Constants/OrderConstants";
import Header from "./../components/Header";
import Message from "./../components/LoadingError/Error";
// import axios from "axios";

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const navigate = useNavigate();

  // Calculate Price
  const calculatePrices = () => {
    const itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0);
    // shippingPrice is initially set to 0.
    let shippingPrice = 0;

    // Check if itemsPrice is greater than or equal to 50 for free shipping
    if (itemsPrice >= 50) {
      // If itemsPrice is greater than or equal to 50, set shippingPrice to 0
      shippingPrice = 0;
    } else {
      // Use dynamic shipping price from each item if itemsPrice is less than 50
      shippingPrice = cart.cartItems.reduce((acc, item) => acc + item.shipping, 0);
    }

    // This ensures that shippingPrice is always a numeric value
    const taxPrice = Number((0.15 * itemsPrice).toFixed(2));
    const totalPrice = (itemsPrice + shippingPrice + taxPrice).toFixed(2);

    return { itemsPrice, shippingPrice, taxPrice, totalPrice };
  };


  const { itemsPrice, shippingPrice, taxPrice, totalPrice } = calculatePrices();

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [navigate, dispatch, success, order]);



  const placeOrderHandler = async () => {
    try {

      const createdOrder = dispatch(
        createOrder({
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice,
          shippingPrice,
          taxPrice,
          totalPrice,
        })
      );
//  We then check if the createdOrder has a valid _id before proceeding ,This ensures that the order creation is successful.
      if (createdOrder && createdOrder._id) {

      }
    } catch (error) {
      console.error("Error placing order:", error.message);
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        {/* Customer Info */}
        <div className="row order-detail">
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className="alert-success order-box">
                  <i className="fas fa-user"></i>
                </div>
              </div>
              <div className="col-md-8 center">
                <h5>
                  <strong>Customer</strong>
                </h5>
                <p>{userInfo.name}</p>
                <p>{userInfo.email}</p>
              </div>
            </div>
          </div>

          {/* Order Info */}
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className="alert-success order-box">
                  <i className="fas fa-truck-moving"></i>
                </div>
              </div>
              <div className="col-md-8 center">
                <h5>
                  <strong>Order info</strong>
                </h5>
                <p>Shipping: {cart.shippingAddress.country}</p>
                <p>Payment method: {cart.paymentMethod}</p>
              </div>
            </div>
          </div>

          {/* Delivery Info */}
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className="alert-success order-box">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
              </div>
              <div className="col-md-8 center">
                <h5>
                  <strong>Deliver to</strong>
                </h5>
                <p>
                  Address: {cart.shippingAddress.city}, {cart.shippingAddress.state} {cart.shippingAddress.street},{" "}
                  {cart.shippingAddress.zipcode}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Order Products */}
        <div className="row order-products justify-content-between">
          <div className="col-lg-8">
            {cart.cartItems.length === 0 ? (
              <Message variant="alert-info mt-5">Your cart is empty</Message>
            ) : (
              cart.cartItems.map((item, index) => (
                <div className="order-product row" key={index}>
                  <div className="col-md-3 col-6">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="col-md-5 col-6 d-flex align-items-center">
                    <Link to={`/products/${item.product}`}>
                      <h6>{item.name}</h6>
                    </Link>
                  </div>
                  <div className="mt-3 mt-md-0 col-md-2 col-6  d-flex align-items-center flex-column justify-content-center">
                    <h4>QUANTITY</h4>
                    <h6>{item.qty}</h6>
                  </div>
                  <div className="mt-3 mt-md-0 col-md-2 col-6 align-items-end d-flex flex-column justify-content-center">
                    <h4>SUBTOTAL</h4>
                    <h6>${item.qty * item.price}</h6>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Order Summary */}
          <div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <strong>Products</strong>
                  </td>
                  <td>${itemsPrice}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Shipping</strong>
                  </td>
                  <td>${shippingPrice}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Tax</strong>
                  </td>
                  <td>${taxPrice}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>${totalPrice}</td>
                </tr>
              </tbody>
            </table>
            {cart.cartItems.length > 0 && (
              <button type="submit" onClick={placeOrderHandler}>
                PLACE ORDER
              </button>
            )}
            {error && (
              <div className="my-3 col-12">
                <Message variant="alert-danger">{error}</Message>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceOrderScreen;