import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function PrivateRoutes() {
  let token = localStorage.getItem("userInfo");
  const location = useLocation();
  
  return (
    token ? (
      <Outlet />
    ) : (
      <Navigate to="/login" replace state={{ from: location }} />
    )
  );
}

export default PrivateRoutes;
