import React from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SingleProduct from "./screens/SingleProduct";
import Login from "./screens/Login";
import Register from "../src/screens/Register";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import NotFound from "./screens/NotFound";
import PrivateRoutes from "./PrivateRoutes";
import ReactGA from 'react-ga4';

const App = () => {
ReactGA.initialize("G-BSNFH26GSZ")

  return (

    <div className="app">
      <Router>
        <Routes>

          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/search/:keyword" element={<HomeScreen />} exact />
          <Route path="/page/:pagenumber" element={<HomeScreen />} exact />
          <Route
            path="/search/:keyword/page/:pageNumber"
            element={<HomeScreen />}
            exact
          />
          <Route path="/products/:id" element={<SingleProduct />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register/>} />

          {/* <Route path="/profile" element={<PrivateRoutes element={<ProfileScreen/>} />} /> */}
          <Route  element={<PrivateRoutes/>}>
          <Route element={<ProfileScreen/>} path="/profile" exact ></Route>

          </Route>

          <Route path="/cart/:id?" element={<CartScreen />} />
          <Route path="/login/shipping" element={<ShippingScreen />} />
          {/* missing route was causing new users to be re routed the page not found, after creating their account. adding the reigstershipping route fixed the issue. */}
          <Route path="/register/shipping" element={<ShippingScreen />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />
          <Route path="/order/:id" element={<OrderScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
